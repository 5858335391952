import React, { useEffect } from "react";
import {
    HashRouter as Router,
    Route,
    Redirect,
    Switch
} from "react-router-dom";

import './css/app.css';

import Home from './pages/index';
import PrivacyPolicy from './pages/privacypolicy';
import Team from './pages/team';
import NotFound from './pages/notfound';

const App = () => {
    useEffect(()=> {
        fetch('https://dario-database.herokuapp.com/', {
            method: 'get',
            mode: 'no-cors'
        });

        fetch('https://dario-oa2.herokuapp.com/', {
            method: 'get',
            mode: 'no-cors'
        });

        fetch('https://dario-ccs.herokuapp.com/', {
            method: 'get',
            mode: 'no-cors'
        });

    }, []);
    
    return (
            <Router>
                <Switch> 
                    <Route exact path="/" component={Home} />
                    <Route exact path="/privacy" component={PrivacyPolicy} />
                    <Route exact path="/404" component={NotFound} /> 
                    <Route exact path="/team" component={Team} />
                    <Redirect to="/404" /> 
                </Switch> 
            </Router>
    );
};

export default App;