import React, { useState, useEffect } from 'react';
import Figure from 'react-bootstrap/Figure';
import phone_notif from '../static/phone_notif_2x.png';
import black_phone from '../static/black_phone.svg';

const PhoneNotification = ({ width, height, visibleAt }) => {
    const [imgSrc, setImgSrc] = useState(black_phone);

    const toggleImgSrc = () => {
        if (window.pageYOffset > visibleAt[0] && window.pageYOffset < visibleAt[1]) {
            setImgSrc(phone_notif);
        } else {
            setImgSrc(black_phone);
        }
    };

    const listener = e => {
        toggleImgSrc();
    }

    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        }
    }, []);

    return (
       <Figure>
           <Figure.Image
            width={width}
            height={height}
            src={imgSrc}
            > 
            </Figure.Image>
       </Figure> 
    );
};

PhoneNotification.defaultProps = {
    width: 230,
    height: "auto",
    visibleAt: [2000, 2300]
};

export default PhoneNotification;