import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

export default function WaitList({ isLarge }) {
    const [email, setEmail] = useState('');

    const handleClick = () => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if (re.test(email)) {
            setTimeout(() => {
                fetch('https://dario-oa2.herokuapp.com/waitlist/' + email, {
                    method: 'post',
                    mode: 'no-cors'
                }).then(data => {
                    console.log(data);
                    console.log('Response received');
                });

            }, 3000);
        
        } else {
            alert('Please input a valid email.');
        }

        setEmail('');

    };
    
    return (
        <InputGroup size={ isLarge ? "lg":"md" } className="mb-3">
            <FormControl
                placeholder="Email"
                value={email} 
                onChange={event => {
                    setEmail(event.target.value)
                }} 
                required
            />
            <InputGroup.Append>
                <Button
                    variant="dark"
                    onClick={handleClick}
                    style={{
                        borderColor: '#14D4BD',
                        outlineColor: '#14D4BD',
                        backgroundColor: '#14D4BD',
                        color: 'black'
                    }} 
                    >
                        Sign Up
                </Button>
            </InputGroup.Append>
        </InputGroup>
    );
};

WaitList.defaultProps = {
    isLarge: true
};