import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import WaitList from './waitlist';

import '../css/footer.css';

import insta from '../static/insta.svg';
import twitter from '../static/twitter.svg';
import facebook from '../static/facebook.svg';

const Footer = ({ size }) => {
    const isLarge = useMediaQuery({
        minDeviceWidth: 1024
    });
    
    return (
        <Jumbotron fluid className="footer">
            <Container>
                <Row>
                    <Col style={{ listStyleType: "none"}}>
                        <h1 
                            id={ isLarge && size === 'lg' ? "index-h1-lg" : "index-h1-sm" } 
                            // id="index-h1-sm" 
                            className="footer-head"
                        >Join the Waitlist</h1> 
                    </Col>  
                </Row>

                <Row>
                    <Col></Col> 
                    <Col xs={isLarge ? 6 : 8 }>
                        <WaitList isLarge={isLarge} />
                    </Col> 
                    <Col></Col> 
                </Row> 

                <Row style={{ marginBottom: '150px' }}>

                </Row>

                <Row>
                    <Col>
                    <img className="social-links" 
                        src={insta}
                        width={25}
                        height={25}
                    ></img>
                    <img className="social-links" 
                        src={twitter}
                        width={25}
                        height={25}
                    ></img>
                    <img className="social-links" 
                        src={facebook}
                        width={25}
                        height={25}
                    ></img>
                    </Col> 
                </Row> 
                
                <Row>
                    <Col>
                        <h6 id="copyright-tag">Copyright © 2020 Dario. All rights reserved.</h6> 
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ul>
                            { size !== 'lg' ? <li id="link-item"><a id="list-item-link" href="/">Home <span style={{color: 'white'}}>&#47;&#47;</span>{' '}</a></li> : undefined }
                            <li id="link-item"><a id="list-item-link" href="mailto:privacy.dario@gmail.com">Contact <span style={{color: 'white'}}>&#47;&#47;</span>{' '}</a></li> 
                            <li id="link-item"><a id="list-item-link" href="/#/privacy">Privacy <span style={{color: 'white'}}>&#47;&#47;</span>{' '}</a></li> 
                            <li id="link-item"><a id="list-item-link" href="/#/team">Team</a></li> 
                        </ul> 
                    </Col>
                </Row>

            </Container>
        </Jumbotron>
    );
};

Footer.defaultProps = {
    size: "lg" 
};

export default Footer;