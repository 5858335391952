import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import MenuBar from '../components/menubar';
import TemplatePage from '../components/page';
import PhoneNotification from '../components/phonenotification';
import CalReveal from '../components/calreveal';
import ScrollArrow from '../components/scrollarrow';
import Footer from '../components/footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Fade from 'react-reveal/Fade';

import '../css/home.css';

import scheduling from '../static/scheduling.svg';
import easy from '../static/easy.svg';
import phone from '../static/phone.svg';

const Home = () => {
    const isLarge = useMediaQuery({
        minDeviceWidth: 1024
    });

    const scaleWidth = (width, factor) => {
        return isLarge ? width : width * factor;
    };

    return (
        <div
            className={
                isLarge ? 
                "triangleBackground" : "" }
            id="index-enclosing-tag"
            >
            <MenuBar isLarge={isLarge} />
            <TemplatePage
                visual={
                    <img src={phone} width={scaleWidth(320, 5/8)} height="auto"/>
                }
                horizontal="left"
                vertical="top"
                clsName={ isLarge ? "index-custom" : "index-page" }>
                <h1 
                    id={ isLarge ? "index-h1-lg" : "index-h1-sm" }
                >Hi, I'm <span id="word-emphasis">Dario</span>,</h1>
                <h3
                    id={ isLarge ? "index-h3-lg" : "index-h3-sm" } 
                >the friendly calendar assistant.</h3>
            </TemplatePage>

            <Row>
                <Col style={{ marginTop: '0px', marginBottom: '0px', textAlign: 'center' }}>
                    <ScrollArrow 
                        visibleAt={
                            isLarge ? 45 : 18
                        }
                        direction="down"
                        scrollTo={
                            isLarge ? 815 : 605
                        }
                    /> 
                </Col>
            </Row> 

            <TemplatePage
                visual={
                    <Fade left>
                        <img src={scheduling} width={scaleWidth(450, 2/3)} height="auto"/>
                    </Fade> 
                }
                horizontal="right"
                vertical="top"
                clsName={ isLarge ? "index-custom" : "index-page" }
                >
                <h1
                    id={ isLarge ? "index-h1-lg" : "index-h1-sm" } 
                >Scheduling is <span id="word-emphasis">hard</span>.</h1>
            </TemplatePage>

            <TemplatePage
                visual={
                    <Fade right>
                        <img src={easy} width={scaleWidth(500, 3/5)} height="auto"/>
                    </Fade> 
                }
                horizontal="left"
                vertical="top">
                <h1
                    id={ isLarge ? "index-h1-lg" : "index-h1-sm" } 
                >Dario makes it <span id="word-emphasis">easy</span>.</h1>
            </TemplatePage>

            <TemplatePage
                visual={
                    <Fade left>
                        <PhoneNotification
                            width={scaleWidth(300, 4/5)}
                            height="auto"
                            visibleAt={
                                isLarge ?
                                [1800, 2250] :
                                [1750, 2050]
                            }
                        />
                    </Fade> 
                }
                horizontal="right"
                vertical="top">
                <h1
                    id={ isLarge ? "index-h1-lg" : "index-h1-sm" } 
                >Dario is <span id="word-emphasis">frictionless</span>.</h1>
                <h3
                    id={ isLarge ? "index-h3-lg" : "index-h3-sm" } 
                >
                    Dario handles all of the negotiations for you, whether your 
                    participants are using Dario or not, then it simply schedules the meeting
                    and notifies you.
                </h3>
            </TemplatePage>
            
            <TemplatePage
                visual={
                    <Fade right>
                        <CalReveal
                            width={scaleWidth(325, 4/5)}
                            height="auto"
                            visibleAt={ isLarge ? 2800 : 2600 }
                        />
                    </Fade> 
                }
                horizontal="left"
                vertical="top">
                <h1
                    id={ isLarge ? "index-h1-lg" : "index-h1-sm" } 
                >Dario <span id="word-emphasis">understands</span> your schedule.</h1>
                <h3
                    id={ isLarge ? "index-h3-lg" : "index-h3-sm" }  
                >
                    Dario is built on artificial intelligence so the more you use it
                    the better if gets at scheduling for you and everyone you schedule
                    with. It learns your habits and patterns to give you the best user
                    experience.
                </h3>
            </TemplatePage>
            
            {/* <TemplatePage
                visual={
                    <CalReveal width={scaleWidth(325, 4/5)} height="auto"/> 
                }
                horizontal="right"
                vertical="top">
                <h1
                    id={ isLarge ? "id-h1-lg" : "id-h1-sm" }
                >Dario is <span id="word-emphasis">everywhere</span>.</h1>
                <h3
                    id={ isLarge ? "id-h3-lg" : "id-h3-sm" }
                >
                    You can access Dario from any of your devices, at anytime and from
                    multiple applications where Dario integrates seamleassly.
                </h3>
            </TemplatePage> */}

            <Row>
                <Col style={{ marginTop: '0px', marginBottom: '40px', textAlign: 'center', backgroundColor: '#F4F3F7' }}>
                    <ScrollArrow
                        direction="up"
                        visibleAt={
                            isLarge ? 2910 : 3130
                        }
                        scrollTo={0}
                    />
                </Col>
            </Row>

            <Footer size="lg" />
        </div>
    );
};

export default Home;