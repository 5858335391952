import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import MenuBar from '../components/menubar';
import Footer from '../components/footer';
import TeamPic from '../components/teampic';

import quinn from '../static/quinn2.jpg';
import rafael from '../static/rafael2.jpg';
import clay from '../static/clay.jpeg';
import abbas from '../static/abbas.jpg';
import joseph from '../static/joseph2.jpg';
import lauren from '../static/lauren2.jpg';

export default function Team() {
    useEffect(() => {
        window.scrollTo({
            top: 0
            // behavior: 'smooth'
        })
    }, []);  
    
    const isLarge = useMediaQuery({
        minDeviceWidth: 1024
    });

    return (
        <div>
            <MenuBar isLarge={false} /> 
            <Container>
            <Row style={{ textAlign: 'center', marginBottom: '30px' }}>
                <Col> 
                <h1
                    style={{
                        color: '#3C21B3',
                        fontWeight: 'bolder',
                        fontSize: '55px'
                    }} 
                >
                    Meet Our Team
                </h1>
                </Col>
            </Row>
                
            { isLarge ?
            <div> 
            <Row>
                <Col>
                    <TeamPic imgSrc={quinn} name="Quinn Leary" position="" />
                </Col> 
                <Col>
                    <TeamPic imgSrc={rafael} name="Rafael Schleder" position="" link="https://rschleder.com/" />
                </Col> 
                <Col>
                    <TeamPic imgSrc={clay} name="Clay Maksymiuk" position="" link="https://cmaks.dev" /> 
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <TeamPic imgSrc={abbas} name="Abbas Ahmed" position="" link="https://abbasaa.dev/#/" />
                </Col> 
                <Col>
                    <TeamPic imgSrc={joseph} name="Joseph Ryan" position="" /> 
                </Col>
                <Col>
                    <TeamPic imgSrc={lauren} name="Lauren Maksymiuk" position="" link="https://www.laurenmaksymiuk.com/" />
                </Col> 
            </Row>
            </div> 
            
            :
            <div>
            
            <Row><Col>
                <TeamPic imgSrc={quinn} name="Quinn Leary" position="" />
            </Col></Row>
            
            <Row><Col>
                <TeamPic imgSrc={rafael} name="Rafael Schleder" position="" link="https://rschleder.com/" />
            </Col></Row>

            <Row><Col>
                <TeamPic imgSrc={clay} name="Clay Maksymiuk" position="" link="https://cmaks.dev" /> 
            </Col></Row>

            <Row><Col>
                <TeamPic imgSrc={abbas} name="Abbas Ahmed" position="" link="https://abbasaa.dev/#/" />
            </Col></Row>

            <Row><Col>
                <TeamPic imgSrc={joseph} name="Joseph Ryan" position="" /> 
            </Col></Row>

            <Row><Col>
                <TeamPic imgSrc={lauren} name="Lauren Maksymiuk" position="" link="https://www.laurenmaksymiuk.com/" />
            </Col></Row>
            
            </div>
            
            }
            
            </Container>   
            <Footer size="sm" />
        </div>
    );
};
