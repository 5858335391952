import React, { useState, useEffect } from 'react';
import MenuBar from '../components/menubar';
import Footer from '../components/footer';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const NotFound = () => {
    const [counter, setCounter] = useState(5); 
    
    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        if (counter === 0) {
            window.location.href = window.location.origin;
        }
    }, [counter]);

    return (
        <div>
            <MenuBar />
            <Jumbotron fluid className="index-page" >
                <Container>
                    <Row>
                        <Col>
                            <h1><span id="word-emphasis">Oops!</span> Looks like the page you are looking for doesn't exist.</h1>
                            <h3>You will be redirected in <span id="word_emphasis">{counter}</span> seconds.</h3> 
                            <h3>
                                Or <a href="/">Click Here</a> to go back home now. 
                            </h3>
                        </Col>  
                    </Row> 
                </Container>
            </Jumbotron>

            <Footer size='sm'/>
        </div>

    );
};

export default NotFound;