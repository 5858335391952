import React from 'react';
import Row from 'react-bootstrap/Row';

export default function TeamPic({ imgSrc, width, height, name, position, link }) {
    return (
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <img 
                src={imgSrc}
                width={width}
                height={height}
                style={{
                    clipPath: "circle(50% at 50% 50%)",
                    marginBottom: "5px"
                }}
            />

            <h4
                style={{
                    color: '#3C21B3'
                }} 
            >
                <a style={{}} href={link}>
                    {name}
                </a> 
            </h4>
            <h5
                style={{
                    // color: '#3C21B3'
                }}
            >
                {position}
            </h5>
        
        </div> 
    );
};

TeamPic.defaultProps = {
    imgSrc: undefined,
    width: 150,
    height: "auto",
    name: "Name",
    position: "Position",
    link: "#"
};