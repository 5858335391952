import React, { useEffect } from 'react';
import MenuBar from '../components/menubar';
import Footer from '../components/footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../css/privacypolicy.css';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0
            // behavior: 'smooth'
        })
    }, []);

    return (
        <div id="privacy-div">
            <MenuBar isLarge={false} />
            <Container>
                <Row><Col className="text-center"><h4 id="privacy-title">Privacy Policy</h4></Col></Row> 
                <Row>
                    <Col>
                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Date of Last Revision: May 6, 2020</h5>
                    <hr/>
                    <p>
                        This privacy policy sets forth Dario’s policies with regard to personally identifiable data (henceforth referred to as “personal data”) and any data collected from the use of Darios’s web site (henceforth referred to as “site”) and web based application (henceforth referred to as “web app”), as well as any other Dario (henceforth referred to as “we”, “us” or “our”) service by visitors and users (henceforth referred to as “you”).  Both personally identifiable data and otherwise aggregated data will be collectively referred to as “information” when the distinction is not necessary.  This Site is operated by Dario and has been created to provide information about our company and its services.  The information herein will detail the use of information by Dario.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Third-Party Service Integration</h5>
                    <p>
                        Our service allows you to integrate and create commands for various online third-party services ("Third-Party services"). In order to take advantage of this feature, you may need to authenticate, register for or log into Third-Party services through the service or on the websites of their respective providers. When you enable linking between or log in to Third-Party services through the service, we will collect relevant information necessary to enable the service to access that Third-Party service and your data and content contained within that Third-Party service ("Login Credentials"). We store your Login Credentials long enough to enable linking to the Third-Party service.
                        </p>
                    <p>
                        When you enable the service to link content and data between Third-Party services, the Third-Party services will provide us with access to certain information that you may have provided to the Third-Party services, and we will use, store and disclose such information in accordance with this privacy policy and the rules you set to govern the linking. In addition, you can use the service to share content and personal data amongst the Third-Party services you integrate with the service. Please remember that the manner in which Third-Party services use, store and disclose your information is governed by the policies of such Third-Party services, and Dario shall have no liability or responsibility for the privacy practices or other actions of any Third-Party services that may be enabled within the service.
                        </p>
                    <p>
                        We may retain certain personally non-identifiable information related to the data or content linked between Third-Party services (for example, date sent, link configuration, names of the Third-Party services), for the purpose of improving our services and as described below in the "Aggregated personal data" section.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Information Collection by Us</h5>
                    <p>
                        During any interaction that you have with us, we may collect personal data and other information from you, as per the detailing hereunder.  There are three main ways through which we collect data: cookies, data provided by you via the use of our service and aggregated general usage data.  These are explained hereunder.
                        </p>

                    <ol>
                        <li>
                            <b>Cookies:</b> In operating our services, we may use a technology called "cookies." A cookie is a piece of information that the device that hosts our services gives to your browser when you access our services. Our cookies help provide additional functionalities to the services and help us analyze our services’ usage more accurately. For instance, our services may set a cookie on your browser that allows you to access the services without needing to restart a session more than once during a visit to our services. In all cases in which we use cookies, we will not collect personal data except with your permission. On most web browsers, you will find a "help" section on the toolbar. Please refer to this section for information on how to receive notification when you are receiving a new cookie and how to turn cookies off. We recommend that you leave cookies turned on because they allow you to take advantage of some of the services' s features.
                            </li>
                        <li>
                            <p>
                                <b>Personal data that you provide through our services:</b> We collect personal data from you when you voluntarily provide such information, such as when you contact us with inquiries, respond to one of our surveys, register for access to the Dario services or use some of services. Wherever Dario collects personal data we make an effort to provide a link to this privacy policy. However, please be aware that some of our systems run continuously, so we will not constantly include this policy in every interaction you have with us, or our system.  To this avail, you will always be properly informed, warned and asked to consent when you are about to use a Dario service that uses information that you have not already been made aware of. You can choose at any time to opt out by contacting support at privacy.dario@gmail.com.
                                </p>
                            <p>
                                By voluntarily providing us with information, you are consenting to our use of it in accordance with this privacy policy. If you provide personal data to our services, you acknowledge and agree that such personal data may be transferred from your current location to the offices and servers of Dario as well as the authorized third parties referred to herein.  These include but are not limited to databases that are themselves hosted on Google Cloud services.  These third party services are commercial web based services that support Dario technologies’ operation.  These are likely to change as Dario evolves; we will make a reasonable attempt to update this privacy policy as these technological changes happen.  The personal information that you provide can be accessed at any time by contacting our customer support at privacy.dario@gmail.com.
                                </p>
                        </li>

                        <li>
                            <p>
                                <b>Personal Data That You Provide Through the services:</b> We collect personal data from you when you voluntarily provide such information, such as when you contact us with inquiries, respond to one of our surveys, register for access to Dario or use certain Dario services. Wherever Dario collects personal data we make an effort to provide a link to this privacy policy. You can choose at any time to opt out by contacting support at privacy.dario@gmail.com.
                                </p>
                            <p>
                                By voluntarily providing us with personal data, you are consenting to our use of it in accordance with this privacy policy. If you provide personal data to the services, you acknowledge and agree that such personal data may be transferred from your current location to the offices and servers of Dario and the aforementioned authorized third parties referred to herein. The personal information that you provide can be accessed at any time by contacting our customer support at privacy.dario@gmail.com.
                                </p>
                        </li>

                        <li>
                            <b>Aggregated Personal Data:</b> In an ongoing effort to better understand and serve the users of the Dario, we often conduct research on its customer demographics, interests and behavior based on the personal data and other information provided to us. This research may be compiled and analyzed on an aggregate basis, and Dario may share this aggregate data with its affiliates, agents and business partners. This aggregate information does not identify you personally.  Dario may also disclose aggregated user statistics in order to describe our services to current and prospective business partners, and to other third parties for other lawful purposes.
                            </li>
                    </ol>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Our Use of Your Personal Data and Other Information</h5>
                    <p>
                        Dario uses the personal data you provide in a manner that is consistent with this privacy policy. If you provide personal data for a certain reason, we may use the personal data in connection with the reason for which it was provided.  For instance, if you contact us by email, we will use the personal data you provide to answer your question or resolve your problem. Also, if you provide personal data in order to obtain access to a Dario service, we will use your personal data to provide you with access to such services and to monitor your use of such services. We may also use your personal data and other personally non-identifiable information collected through the service to help us improve the content and functionality of the services, to better understand our users and to improve Dario services. Dario and its affiliates may use this information to contact you in the future to tell you about services we believe will be of interest to you. If we do so, each communication we send you will contain instructions permitting you to "opt-out" of receiving future communications. In addition, if at any time you wish not to receive any future communications or you wish to have your name deleted from our mailing lists, please contact us as indicated below.
                        </p>

                    <p>
                        If Dario intends on using any personal data in any manner that is not consistent with this privacy policy, you will be informed of such anticipated use prior to or at the time at which the personal data is collected.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Our Disclosure of Your Personal Data and Other Information</h5>
                    <p>
                        You can visit the services without providing any personal data. If you choose not to provide any personal data, you may not be able to use certain Dario services or they may have reduced functionality.
                        </p>
                    <p>
                        There are, however, certain circumstances in which we may share your personal data with certain third parties without further notice to you, as set forth below:
                        </p>
                    <p>
                        <b>Business Transfers:</b>  As our company develops, we may sell any part of our business or buy other businesses and assets.  In the case where we undergo a corporate reorganisation, such as a sale, merger, reorganisation, dissolution or other similar event, we may transfer your personal data.
                        </p>

                    <p>
                        In cases of onward transfer of personal information to third parties of data of EU and Swiss individuals received pursuant to the EU-U.S. and Swiss-U.S. privacy Shield, Dario remains liable for preserving your data internally.
                        </p>

                    <p>
                        <b>Related Companies:</b> We may also share your personal data with our Related Companies for purposes consistent with this privacy policy.
                        </p>

                    <p>
                        <b>Agents, Consultants and Related Third Parties:</b> Dario, like many businesses, sometimes hires other companies to perform certain business-related functions. Examples of such functions include mailing information, maintaining databases and processing payments. When we employ another company to perform a function of this nature, we only provide them with the information that they need to perform their specific function.
                        </p>

                    <p>
                        <b>Business Partners:</b> If one of our business partners refers you to us, we may disclose personal data pursuant to our agreement with that business partner. We do not control and are not responsible for the privacy practices of our business partners.
                        </p>

                    <p>
                        <b>Legal Requirements:</b> Dario may disclose your personal data if required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal obligation, (ii) protect and defend the rights or property of Dario, (iii) act in urgent circumstances to protect the personal safety of users of the services or the public, or (iv) protect against legal liability.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">California Consumer Privacy Act (CCPA)</h5>
                    <p>
                        To the extent provided for by law and subject to applicable exceptions, California residents have the following privacy rights in relation to the personal information we collect:
                        </p>

                    <p>
                        The right to know what personal information we have collected and how we have used and disclosed that personal information;
                        </p>

                    <p>
                        The right to request deletion of your personal information; and the right to be free from discrimination relating to the exercise of any of your privacy rights.
                        </p>
                    <p>
                        <b>Exercising Your Rights:</b> California residents can exercise the above privacy rights by emailing us at: privacy.dario@gmail.com
                        </p>
                    <p>
                        <b>Verification:</b> in order to protect your personal information from unauthorized access or deletion, we may require you to verify your login credentials before you can submit a request to know or delete personal information. If you do not have an account with us, or if we suspect fraudulent or malicious activity, we may ask you to provide additional personal information for verification. If we cannot verify your identity, we will not provide or delete your personal information.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Exclusions</h5>
                    <p>
                        This privacy policy does not apply to any personal data collected by Dario other than personal data collected through our services. This privacy policy shall not apply to any unsolicited information you provide to Dario through the services or through any other means. This includes, but is not limited to, information posted to any public areas of the services, such as bulletin boards (collectively, "Public Areas"), any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, "unsolicited information"). All unsolicited information shall be deemed to be non-confidential and Dario shall be free to reproduce, use, disclose, and distribute such unsolicited information to others without limitation or attribution.
                        </p>
                    <p>
                        If you are invited to join a Dario team account, and you accept the invitation, you are agreeing that certain of your information will be shared with the team account holder and other team members. In particular, the team account holder will have access to your name, email address, avatar (if any) and task usage, and other team members will have access to your name, email address and avatar (if any).  Any information you share via a team account, including third-party services you link to, will be available to all team members of the team account you have joined. You are solely responsible for any information you share via a team account, which is posted at your own risk.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Children</h5>
                    <p>
                        Dario does not knowingly collect personal data from children under the age of 13. If you are under the age of 13, please do not submit any personal data through the services. We encourage parents and legal guardians to monitor their children's internet usage and to help enforce our privacy policy by instructing their children never to provide personal data on the services without their permission. If you have reason to believe that a child under the age of 13 has provided personal data to Dario through our services, please contact us, and we will endeavor to delete that information from our databases.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Links to Other Web Sites</h5>
                    <p>
                        This privacy policy applies only to the services. The services may contain links to other web sites not operated or controlled by Dario (the "Third-Party Sites"). The policies and procedures we described here do not apply to the Third-Party Sites. The links from the services do not imply that Dario endorses or has reviewed the Third-Party Sites. We suggest checking their privacy policy or contacting the Site/Service administrators for information on their privacy policies.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Security</h5>
                    <p>
                        Dario takes reasonable steps to protect the personal data provided via the services from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the services may not be secure. Therefore, you should take special care in deciding what information you send to us via email. Please keep this in mind when disclosing any personal data to Dario via the Internet.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Changes to Dario's privacy policy</h5>
                    <p>
                        The services and our business may change from time to time. As a result, at times it may be necessary for Dario to make changes to this privacy policy. Dario reserves the right to update or modify this privacy policy at any time and from time to time. If we do this, we will post the changes to this privacy policy on this page and will indicate at the top of this page the date these terms were last revised. We will also notify you, either through the service user interface, in an email notification or through other reasonable means. Please review this policy periodically, and especially before you provide any personal data.  This privacy policy was last updated on the date indicated above. Your continued use of the service after the date any such changes become effective constitutes your acceptance of the new privacy policy.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Other Terms</h5>
                    <p>
                        Your access to and use of the services is subject to the terms of service which may be found on our site at https://geddes.dev/dario/terms/.
                        </p>
                </div>

                <hr/>
                <div className="section">
                    <h5 className="privacy-headers">Contacting Dario</h5>
                    <p>
                        To keep your personal data accurate, current, and complete, please contact us as specified below. We will take reasonable steps to update or correct personal data in our possession that you have previously submitted via the services. Please also feel free to contact us if you have any questions about Dario's privacy policy or the information practices of the services.
                        </p>
                    <p>You may contact us as follows: privacy.dario@gmail.com.</p>
                </div>
                    </Col>
                </Row>
            </Container>

            <Footer size="sm"/>
        </div>
    );

};

export default PrivacyPolicy;