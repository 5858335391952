import React, { useState, useEffect } from 'react';
import Fade from 'react-bootstrap/Fade';
import down_arrow from '../static/down_arrow.svg';
import up_arrow from '../static/up_arrow.svg';

const ScrollArrow = ({ visibleAt, direction, scrollTo}) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > visibleAt) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const listener = e => {
        toggleVisibility();
    }

    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        }
    }, []);


    const handleClick = () => {
        window.scrollTo({
            top: scrollTo,
            behavior: 'smooth'
        });
    };

    return (
        <div>
            <Fade in={isVisible}>
                { direction === 'down' ?
                <img 
                    id="down-arrow" 
                    onClick={handleClick} 
                    src={down_arrow}
                    width={75}
                    height="auto"
                ></img>
                :
                <img 
                    id="up-arrow" 
                    onClick={handleClick} 
                    src={up_arrow}
                    width={75}
                    height="auto"
                ></img> }
            </Fade> 
        </div>
    );
};

ScrollArrow.defaultProps = {
    visibleAt: 45,
    direction: 'down',
    scrollTo: 1000
};

export default ScrollArrow;