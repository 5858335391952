import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import '../css/menubar.css';
import logo from '../static/logo.svg';

/**
 * MenuBar component contains links to pages
 */
const MenuBar = ({ notTransparentAt, isLarge }) => {
    const [menuColor, setMenuColor] = useState('transparent');

    const toggleTransparency = () => {
        if (window.pageYOffset > notTransparentAt) {
            setMenuColor('#F4F3F7');
        } else {
            setMenuColor('transparent');
        }
    };

    const listener = e => {
        toggleTransparency();
    }

    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        }
    }, []);
    
    const isMobile = useMediaQuery(
        { minDeviceWidth: 768 }
    );

    const handleClick = () => {
        window.scrollTo({
            top: 4000,
            behavior: 'smooth'
        })
    };

    return (
        <Navbar 
            navbarMcollapseOnSelect
            className="color-nav"
            sticky="top"
            expand="sm"
            style={
                isLarge ?
                { backgroundColor: menuColor } :
                { backgroundColor: '#F4F3F7'}
            }
            >
            <Navbar.Brand id="navbar-logo" href="/">
                <img
                    src={logo}
                    width="70"
                    height="auto"
                    className="d-inline-block align-middle"
                /> 
            </Navbar.Brand>
            <Navbar.Toggle id="navbar-toggle" aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                
                <Nav.Link className="justify-content-end" id="contact-link" href="mailto:privacy.dario@gmail.com">contact</Nav.Link>
                <Nav.Link className="justify-content-end" id="team-link" href="/#/team">team</Nav.Link>
                <Nav.Link className="justify-content-end" id="signup-link" href="#" onClick={handleClick}>
                    sign up
                </Nav.Link>
            </Navbar.Collapse>
        </Navbar>
    );

}

MenuBar.defaultProps = {
    notTransparentAt: 650,
    isLarge: true
};

export default MenuBar;