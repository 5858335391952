import React, { useState, useEffect } from 'react';
import Figure from 'react-bootstrap/Figure';
import cal1 from '../static/cal1.png';
import cal2 from '../static/cal2.png';

const CalReveal = ({ width, height, visibleAt }) => {
    const [imgSrc, setImgSrc] = useState(cal1);
    
    const toggleImgSrc = () => {
        if (window.pageYOffset > visibleAt) {
            setImgSrc(cal2);
        } else {
            setImgSrc(cal1);
        }
    }

    const listener = e => {
        toggleImgSrc();
    }

    useEffect(() => {
        window.addEventListener("scroll", listener);
        return () => {
            window.removeEventListener("scroll", listener);
        }
    }, []);


    return (
       <Figure>
           <Figure.Image
            width={width}
            height={height}
            src={imgSrc}
           />
       </Figure> 
    );
};

CalReveal.defaultProps = {
    width: 325,
    height: "auto",
    visibleAt: 2800
};

export default CalReveal;