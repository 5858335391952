import React from 'react';
import { useMediaQuery } from 'react-responsive';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import '../css/page.css';

const TemplatePage = ({ visual, horizontal, vertical, clsName, children }) => {
    const isLarge = useMediaQuery({
        query: '(min-device-width: 1224px)'
    });

    return (
        <Jumbotron fluid className={clsName} style={{ margin: 'auto', height: 'auto' }}>
            <Container>
                { !isLarge && vertical === 'top' ?
                <Row>
                    <Col>
                        {children} 
                    </Col>
                </Row> : undefined }
                
                <Row style={{ alignItems: 'center' }}>
                    { isLarge && horizontal === 'left' ?
                        <Col>
                            {children} 
                        </Col> : undefined }

                    <Col style={{ textAlign: 'center' }}>{visual}</Col>
                    
                    { isLarge && horizontal === 'right' ?
                        <Col>
                            {children} 
                        </Col> : undefined }
                </Row>
                
                { !isLarge && vertical === 'bottom' ?
                <Row>
                    <Col>
                        {children} 
                    </Col>
                </Row> : undefined }
            </Container>
        </Jumbotron>
    );
};

TemplatePage.defaultProps = {
    visual: undefined, 
    horizontal: 'left',
    vertical: 'bottom',
    clsName: 'index-page'
};

export default TemplatePage;